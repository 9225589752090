import { useState } from "react";
import { useRouter } from "next/router";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import dynamic from "next/dynamic";

const StyledBox = dynamic(() => import("@components/Custom/Box/StyledBox"));



const StyledInputBase = styled(InputBase)(({ theme }) => ({
  	width: '90%',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(0.5em + ${theme.spacing(0.25)})`,
		paddingRight: `calc(0.5em + ${theme.spacing(1.25)})`,
		transition: theme.transitions.create('width'),
  	},
}));

const HeaderMobileBar2 = () => {
	const router = useRouter()
	const [placeholder, setPlaceholder] =useState("Search")
	
      
	const validateSearchForm = (event) => {
		event.preventDefault();
		const searchTerm = event.target.elements.term.value
		if (searchTerm.trim().length > 0) {
			router.push("/search?term=" + searchTerm);
		}
	};
      
	return (
		<Box className='d-flex pt-2 pb-2 w-100' sx={{backgroundColor:alpha('#ccc', 0.35)}}>
			<Box className=" justify-center align-items-center text-center d-flex w-100 pr-3 pl-2">
				<Box onClick={()=>router.back()} className='f-small' sx={{border:1, borderColor:'#fff', width:'48px', height:'36px', borderRadius:'20px',backgroundColor:alpha('#fff', 1)}} >
					<ArrowBackIcon className='mt-2 f-large' sx={{color: 'primary.main'}} />
				</Box>
				<form className="w-100" onSubmit={validateSearchForm}>
					<Box className='position-relative w-100 ml-2' sx={{ borderRadius:15, border:1, borderColor:'#fff',backgroundColor:alpha('#fff', 0.75)}}>
						<Button
							sx={{
								// padding: 0, 2),
								height: '100%',
								position: 'absolute !important',
								pointerEvents: 'none',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								all: 'unset',
								top: '25%',
								cursor: 'pointer',
								right: 10,
							}}
							aria-label='search_button' 
							name="search_button" 
						>
							<StyledBox>
								<SearchIcon sx={{color: 'primary.main'}}  />
							</StyledBox>
						</Button>
						<StyledInputBase
							placeholder={placeholder}
							inputProps={{ 'aria-label': 'search', name: 'search' }}
							type="text"
							name="term"
							id="term"
						/>
					</Box>
				</form>
			</Box>
		</Box>
	)
}

export default HeaderMobileBar2